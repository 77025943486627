import React from 'react';
import Layout from "../../../components/layout";
import {StaticImage} from "gatsby-plugin-image";

class ArticleSpiritualism extends React.Component {
    render() {
        return (
            <Layout>
                <h1>Spiritualiam - Australasian BLITZ c1990</h1>
                <StaticImage src="../../../images/spiritualism/ResizedImage600826-4181158.jpg"
                             alt="Spiritualism Page 1"/>
                <StaticImage src="../../../images/spiritualism/ResizedImage600824-7177466.jpg"
                             alt="Spiritualism Page 2"/>
                <StaticImage src="../../../images/spiritualism/ResizedImage600819-6802314.jpg"
                             alt="Spiritualism Page 3"/>
            </Layout>);
    }
}

export default ArticleSpiritualism;
